import { RouteListType } from '@router/configuration';
import { isTabRoute } from '@router';

export const getRouteListTypeByRouteName = (routeName: string): RouteListType => {
  const isTab = isTabRoute(routeName);
  const route = isTab
    ? routeName.substring(0, routeName.indexOf('Tab'))
    : routeName;
  return route as RouteListType;
};
