import { constants } from 'router5';
import { routeList, RouteListType } from './routeList';
import { routeComponents as components } from './routeComponents';
import { routeResolver as resolver } from './routeResolvers';
import { getTitleKeyOptions } from '@proliance-ai/design-system';
import { Case, snakeCaseToKebabCase } from '@proliance-ai/utilities';
import { getAvailableFunctionalAreaList } from '@pages/Processes/utilities';
import { translationKeyMapping } from '@pages/ProcessSelection/ProcessSelectionPage';
import { setRoute } from '../utilities/setRoute';
import {
  systemsCheckFunction,
  canActivateByPermission,
  assetManagementCheckFunction
} from './canActivate';
import { PagePermissionData, permissionService } from '@services';
import { filter, take } from 'rxjs/operators';

export const getRoutes = () => permissionService.pagePermissionSubject$
  .pipe(
    filter((value: PagePermissionData | null): value is PagePermissionData => value !== null),
    take(1))
  .toPromise<PagePermissionData>()
  .then((pagePermission: PagePermissionData) => {
    const availableFunctionalAreaList = getAvailableFunctionalAreaList();
    const defaultFunctionalArea = availableFunctionalAreaList[0] || 'HR';
    const processesDefaultTab = snakeCaseToKebabCase(defaultFunctionalArea, Case.lower);
    return [
      setRoute({
        name: 'documentError',
        component: components.DocumentError,
        namespace: 'error',
        titleOptions: getTitleKeyOptions('404.pageTitle'),
        resolve: resolver.documentError
      }),
      setRoute({
        name: 'notAllowed',
        component: components.ErrorPage,
        namespace: 'error',
        titleOptions: getTitleKeyOptions('403.pageTitle'),
        resolve: resolver.error(403)
      }),
      setRoute({
        name: constants.UNKNOWN_ROUTE as RouteListType,
        path: 'notFound',
        params: { path: routeList.notFound },
        component: components.ErrorPage,
        namespace: 'error',
        titleOptions: getTitleKeyOptions('404.pageTitle'),
        resolve: resolver.error()
      }),

      setRoute({
        name: 'default',
        lazy: false
      }),

      setRoute({
        name: 'dpoNomination',
        component: components.DpoNomination,
        resolve: {
          ...resolver.common,
          ...resolver.dpoNomination
        }
      }),
      setRoute({
        name: 'dpoPrivacyPolicy',
        component: components.DpoPrivacyPolicy,
        resolve: {
          ...resolver.common,
          ...resolver.dpoPrivacyPolicy
        }
      }),
      setRoute({
        name: 'dpoNotification',
        component: components.DpoNotification,
        resolve: {
          ...resolver.common,
          ...resolver.dpoNotification
        }
      }),

      setRoute({
        name: 'surveyTaskSystemAssignment',
        namespace: 'dashboard',
        component: components.SurveyTask,
        titleOptions: {
          path: 'surveyData',
          titleKey: 'surveyPageTitle',
          optionKey: 'template.title'
        },
        resolve: {
          ...resolver.common,
          ...resolver.surveySystemAssignment()
        }
      }),
      setRoute({
        name: 'customProcesses',
        component: components.CustomProcesses,
        titleOptions: {
          path: 'customProcessesData.result.result',
          isConditional: true,
          conditionalKey: 'name'
        },
        resolve: {
          ...resolver.common,
          ...resolver.customProcesses()
        }
      }),
      setRoute({
        name: 'customProcessesAdd',
        namespace: 'customProcesses',
        component: components.CustomProcesses,
        titleOptions: {
          path: 'customProcessesData.result.result',
          isConditional: true,
          conditionalKey: 'name'
        },
        resolve: {
          ...resolver.common,
          ...resolver.customProcesses(false)
        }
      }),
      setRoute({
        name: 'processSelection',
        namespace: 'dashboard',
        component: components.ProcessSelection,
        titleOptions: {
          titleKey: 'functionalArea',
          titlePrefix: 'task',
          map: translationKeyMapping
        },
        resolve: {
          ...resolver.common,
          ...resolver.processSelection
        }
      }),
      setRoute({
        name: 'surveyTask',
        namespace: 'dashboard',
        component: components.SurveyTask,
        titleOptions: {
          path: 'surveyData',
          titleKey: 'surveyPageTitle',
          optionKey: 'template.title'
        },
        resolve: {
          ...resolver.common,
          ...resolver.surveyTask()
        }
      }),
      setRoute({
        name: 'surveyTaskView',
        namespace: 'dashboard',
        component: components.SurveyTask,
        titleOptions: {
          path: 'surveyData',
          titleKey: 'surveyPageTitle',
          optionKey: 'template.title'
        },
        resolve: {
          ...resolver.common,
          ...resolver.surveyTask(true, true)
        }
      }),

      setRoute({
        name: 'dashboard',
        component: components.Dashboard,
        resolve: {
          ...resolver.common,
          ...resolver.dashboard
        }
      }),

      setRoute({
        name: 'dashboardTab',
        namespace: 'dashboard',
        component: components.Dashboard,
        resolve: {
          ...resolver.common,
          ...resolver.dashboard
        }
      }),

      setRoute({
        name: 'analytics',
        forwardTo: 'analyticsTab'
      }),
      setRoute({
        name: 'analyticsTab',
        namespace: 'analytics',
        component: components.Analytics,
        defaultParams: {
          routeTab: 'status'
        },
        resolve: {
          ...resolver.analytics
        }
      }),

      setRoute({
        name: 'processes',
        forwardTo: 'processesTab'
      }),
      setRoute({
        name: 'processesTab',
        namespace: 'processes',
        component: components.Processes,
        defaultParams: {
          routeTab: processesDefaultTab
        },
        resolve: {
          ...resolver.processes
        }
      }),

      setRoute({
        name: 'tom',
        component: components.Tom,
        resolve: {
          ...resolver.tom
        }
      }),

      setRoute({
        name: 'risk',
        namespace: {
          risk: 'risk',
          riskStatic: 'static'
        },
        titleOptions: {
          risk: {},
          riskStatic: { titleKey: 'risk.pageTitle' }
        },
        component: {
          risk: components.Risk,
          riskStatic: components.Static
        },
        dynamic: true,
        forwardTo: pagePermission.risk
          ? 'riskTab'
          : undefined,
        resolve: {
          risk: {
            ...resolver.risk
          },
          riskStatic: {
            ...resolver.staticPage(),
            ...resolver.staticPageInfo(false)
          }
        }
      }),
      setRoute({
        name: 'riskTab',
        namespace: 'risk',
        component: components.Risk,
        defaultParams: {
          routeTab: 'active'
        },
        resolve: {
          ...resolver.risk
        }
      }),
      setRoute({
        name: 'riskAdd',
        namespace: 'riskSurvey',
        titleOptions: {
          titleKey: 'pageTitle.create'
        },
        component: components.RiskSurvey,
        resolve: {
          ...resolver.common,
          ...resolver.riskSurvey(false)
        }
      }),
      setRoute({
        name: 'riskEdit',
        namespace: 'riskSurvey',
        component: components.RiskSurvey,
        titleOptions: {
          titleKey: 'pageTitle.edit'
        },
        resolve: {
          ...resolver.common,
          ...resolver.riskSurvey()
        }
      }),
      setRoute({
        name: 'riskView',
        namespace: 'riskSurvey',
        component: components.RiskSurvey,
        titleOptions: {
          titleKey: 'pageTitle.edit'
        },
        resolve: {
          ...resolver.common,
          ...resolver.riskSurvey(true, true, true)
        }
      }),

      setRoute({
        name: 'document',
        forwardTo: 'documentTab'
      }),
      setRoute({
        name: 'documentTab',
        namespace: 'document',
        component: components.Document,
        defaultParams: {
          routeTab: 'private'
        },
        resolve: {
          ...resolver.common,
          ...resolver.document
        }
      }),

      setRoute({
        name: 'websites',
        namespace: 'static',
        titleOptions: { titleKey: 'websites.pageTitle' },
        component: components.Static,
        resolve: {
          ...resolver.dpo,
          ...resolver.staticPage()
        }
      }),

      setRoute({
        name: 'dataSubjectRequests',
        namespace: 'static',
        titleOptions: { titleKey: 'dataSubjectRequests.pageTitle' },
        component: components.Static,
        resolve: {
          ...resolver.dpo,
          ...resolver.staticPage()
        }
      }),

      setRoute({
        name: 'dataBreach',
        namespace: {
          dataBreach: 'dataBreach',
          dataBreachStaticDefault: 'static',
          dataBreachStaticCustom: 'static'
        },
        titleOptions: {
          dataBreach: {},
          dataBreachStaticDefault: { titleKey: 'dataBreach.default.pageTitle' },
          dataBreachStaticCustom: { titleKey: 'dataBreach.custom.pageTitle' }
        },
        component: {
          dataBreach: components.DataBreach,
          dataBreachStaticDefault: components.Static,
          dataBreachStaticCustom: components.Static
        },
        dynamic: true,
        forwardTo: pagePermission.dataBreach
          ? 'dataBreachTab'
          : undefined,
        resolve: {
          dataBreach: {
            ...resolver.dataBreach
          },
          dataBreachStaticDefault: {
            ...resolver.dpo,
            ...resolver.staticPage('dataBreachStaticDefault')
          },
          dataBreachStaticCustom: {
            ...resolver.dpo,
            ...resolver.staticPage('dataBreachStaticCustom')
          }
        }
      }),
      setRoute({
        name: 'dataBreachTab',
        namespace: 'dataBreach',
        component: components.DataBreach,
        defaultParams: {
          routeTab: 'active'
        },
        resolve: {
          ...resolver.dataBreach
        }
      }),
      setRoute({
        name: 'dataBreachAdd',
        namespace: 'dataBreachSurvey',
        titleOptions: { titleKey: 'pageTitle.create' },
        component: components.DataBreachSurvey,
        resolve: {
          ...resolver.common,
          ...resolver.dataBreachSurvey(false)
        }
      }),
      setRoute({
        name: 'dataBreachEdit',
        namespace: 'dataBreachSurvey',
        component: components.DataBreachSurvey,
        titleOptions: { titleKey: 'pageTitle.edit' },
        resolve: {
          ...resolver.common,
          ...resolver.dataBreachSurvey()
        }
      }),
      setRoute({
        name: 'dataBreachView',
        namespace: 'dataBreachSurvey',
        component: components.DataBreachSurvey,
        titleOptions: { titleKey: 'pageTitle.edit' },
        resolve: {
          ...resolver.common,
          ...resolver.dataBreachSurvey(true, true, true)
        }
      }),

      setRoute({
        name: 'courseManagement',
        namespace: [ 'courseManagement', 'course' ],
        component: components.CourseManagement,
        resolve: {
          ...resolver.courseManagement
        }
      }),
      setRoute({
        name: 'myCourses',
        namespace: [ 'myCourses', 'course' ],
        component: components.MyCourses,
        resolve: {
          ...resolver.myCourses
        }
      }),

      setRoute({
        name: 'team',
        component: components.Team,
        resolve: {
          ...resolver.team
        }
      }),
      setRoute({
        name: 'teamDetails',
        component: components.TeamDetails,
        resolve: {
          ...resolver.teamDetails
        }
      }),

      setRoute({
        name: 'systems',
        component: components.Systems,
        resolve: {
          ...resolver.common,
          ...resolver.systems
        },
        canActivate: canActivateByPermission(systemsCheckFunction)
      }),
      setRoute({
        name: 'systemsList',
        component: components.SystemsList,
        resolve: {
          ...resolver.common,
          ...resolver.systemsList
        },
        canActivate: canActivateByPermission(systemsCheckFunction)
      }),
      setRoute({
        name: 'systemsAdd',
        namespace: 'systemsDetails',
        component: components.SystemsDetails,
        titleOptions: { titleKey: 'pageTitle.create' },
        resolve: {
          ...resolver.common,
          ...resolver.systemsAdd
        },
        canActivate: canActivateByPermission(systemsCheckFunction)
      }),
      setRoute({
        name: 'systemsDetails',
        component: components.SystemsDetails,
        titleOptions: { titleKey: 'pageTitle.edit' },
        resolve: {
          ...resolver.common,
          ...resolver.systemsDetails
        },
        canActivate: canActivateByPermission(systemsCheckFunction)
      }),

      setRoute({
        name: 'migratedSystemsList',
        namespace: 'systemsList',
        component: components.SystemsList,
        resolve: {
          ...resolver.common,
          ...resolver.systemsList
        },
        canActivate: canActivateByPermission(systemsCheckFunction)
      }),
      setRoute({
        name: 'migratedSystemsAdd',
        namespace: 'systemsList',
        component: components.SystemsList,
        resolve: {
          ...resolver.common,
          ...resolver.systemsList
        },
        canActivate: canActivateByPermission(systemsCheckFunction)
      }),
      setRoute({
        name: 'migratedSystemsDetails',
        namespace: 'systemsList',
        component: components.SystemsList,
        resolve: {
          ...resolver.common,
          ...resolver.systemsList
        },
        canActivate: canActivateByPermission(systemsCheckFunction)
      }),

      setRoute({
        name: 'assetManagement',
        forwardTo: 'assetManagementTab'
      }),
      setRoute({
        name: 'assetManagementTab',
        namespace: 'assetManagement',
        component: components.AssetManagement,
        defaultParams: {
          routeTab: 'software'
        },
        resolve: {
          ...resolver.assetManagementTab
        },
        canActivate: canActivateByPermission(assetManagementCheckFunction)
      }),
      setRoute({
        name: 'assetManagementTabAsset',
        namespace: 'assetManagement',
        component: components.AssetManagement,
        resolve: {
          ...resolver.assetManagementTabAsset
        },
        canActivate: canActivateByPermission(assetManagementCheckFunction)
      }),
      setRoute({
        name: 'assetManagementTabAssetSidebar',
        namespace: 'assetManagement',
        component: components.AssetManagement,
        resolve: {
          ...resolver.assetManagementTabAssetSidebar
        }
      }),
      setRoute({
        name: 'assetManagementTabAssetSidebarTab',
        namespace: 'assetManagement',
        component: components.AssetManagement,
        resolve: {
          ...resolver.assetManagementTabAssetSidebarTab
        }
      })
    ];
  });

