import { isUuid, ResolverData } from '@router';
import { Observable, of, throwError } from 'rxjs';
import { AssetFormKey } from '@proliance-ai/typings';
import { Case, kebabCaseToSnakeCase } from '@proliance-ai/utilities';
import { AssetManagementTabsKey, ManagementSidebarTabsKey } from '@services';

export interface RouteParameters {
  routeTab?: null | AssetManagementTabsKey;
  id?: string;
  sidebar?: AssetFormKey;
  sidebarTab?: ManagementSidebarTabsKey;
}

const AssetManagementSidebarDictionary: Record<AssetManagementTabsKey, AssetFormKey[]> = {
  DATA_STORAGE: [ 'management', 'type', 'operators', 'dataTypes', 'description', 'accesses', 'rpaAsController', 'rpaAsProcessor' ],
  SOFTWARE: [ 'management', 'type', 'operators', 'vendor', 'dataTypes', 'description', 'categories', 'accesses', 'rpaAsController', 'rpaAsProcessor' ],
  THIRD_PARTY: [ 'management', 'type', 'legalStatuses', 'dataTypes', 'transmissionCountries', 'documents', 'description', 'contractualDeadlines', 'contact', 'privacyContact', 'services', 'accesses', 'rpaAsController', 'rpaAsProcessor' ],
  PRINCIPAL: [],
  WEBSITE: []
};

function isAssetFormKey(key: string, type: AssetManagementTabsKey): key is AssetFormKey {
  return AssetManagementSidebarDictionary[type].includes(key as AssetFormKey);
}

export const assetManagementResolver = (data: ResolverData): Observable<RouteParameters> => {
  const {
    routeTab: routeTabParameter,
    id,
    sidebar,
    sidebarTab: sidebarTabValue
  } = data.parameter;
  const routeTab = kebabCaseToSnakeCase(routeTabParameter, Case.upper) as AssetManagementTabsKey;

  if (!routeTab) {
    return throwError({ status: 404 });
  }

  if (!id) {
    return of({ routeTab });
  }
  const additionalValues = [ 'add', 'filter' ];
  if (!isUuid(id) && !additionalValues.includes(id)) {
    return throwError({ status: 404 });
  }

  if (!sidebar) {
    return of({ routeTab, id });
  }
  if (!isAssetFormKey(sidebar, routeTab)) {
    return throwError({ status: 404 });
  }

  if (!sidebarTabValue) {
    return of({ routeTab, id, sidebar });
  }

  const sidebarTabList = [ 'administration', 'comments', 'protocol' ];
  if (!sidebarTabList.includes(sidebarTabValue)) {
    return throwError({ status: 404 });
  }
  const sidebarTab = sidebarTabValue.toUpperCase() as ManagementSidebarTabsKey;
  return of({ routeTab, id, sidebar, sidebarTab });
};
