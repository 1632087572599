import React, { FC, useEffect, useState } from 'react';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TaskType } from '@interfaces';
import { Collection, ComponentPermissionData, OpenAssetCount, OpenAssetInfo, ProductType } from '@proliance-ai/typings';
import {
  assetDashboardService,
  basicFilter,
  companyDataService,
  ITaskCount,
  permissionService,
  streamService,
  Task,
  TaskCount,
  TaskCountDictionary,
  TaskCountMessage,
  taskService,
  TaskTabDictionary
} from '@services';
import { LabelWithBadge, Widget } from '@shared/components';
import { ICollection } from '@proliance-ai/design-system';
import { checkPermission } from '../../utilities';
import { AssessmentTasks, BasicTasks, TaskTabs } from '..';
import AssetHubTasks from '@pages/Dashboard/components/AssetHubTasks/AssetHubTasks';


interface Props {
  namespace: Namespace;
  permission: ComponentPermissionData;
  product: ProductType;
  basicTaskCount: number;
  basicTaskList: Task[];
  assessmentTaskData: TaskCountDictionary<ITaskCount>;
  tab: TaskCount;
  changeTabHandler: (value: TaskCount) => void;
  openAssetCount: OpenAssetCount;
  openAssetData: OpenAssetInfo[];
}

const TasksWidget: FC<Props> = ({
  namespace,
  permission: initialPermission,
  product,
  basicTaskCount: initialBasicTaskCount,
  basicTaskList: initialBasicTaskList,
  assessmentTaskData: initialAssessmentTaskData,
  tab,
  changeTabHandler,
  openAssetCount: initialOpenAssetCount,
  openAssetData: initialOpenAssetData
}) => {
  const { t } = useTranslation(namespace);

  const [ permission, setPermission ] = useState<ComponentPermissionData>(initialPermission);
  const getIsBasicTasksAvailable = (taskCount?: number): boolean => !!taskCount
    && checkPermission(permission, 'basicTasksWidget');
  const getIsAssessmentTasksAvailable = (taskCount?: number): boolean => !!taskCount
    && checkPermission(permission, 'assessmentTasksWidget')
    && product === 'privacy';

  const calculateAssessmentTaskCount = (
    assessmentTaskData: TaskCountDictionary<ITaskCount>
  ): number => Object.values(assessmentTaskData)
    .reduce((result: number, taskCount: ITaskCount) => result + taskCount.currentUserOpen, 0);
  const [ payload, setPayload ] = useState<TaskTabDictionary<number>>({
    BASIC: initialBasicTaskCount,
    ASSESSMENT: calculateAssessmentTaskCount(initialAssessmentTaskData),
    ASSET_HUB: initialOpenAssetCount?.counts?.TOTAL || 0
  });

  const getAcademyBasicTasks = (basicTaskList: Task[]): Task[] => basicTaskList
    .filter((task: Task) => [ TaskType.ACADEMY, TaskType.ACADEMY_INVITE_EMPLOYEE ]
      .some((type: TaskType): boolean => type === task.type));
  const getBasicTaskListValue = (taskList: Task[]): Task[] => product === 'privacy'
    ? taskList
    : getAcademyBasicTasks(taskList);
  const [ basicTaskCount, setBasicTaskCount ] = useState<number>(initialBasicTaskCount);
  const [ isBasicTasksAvailable, setIsBasicTasksAvailable ] = useState<boolean>(getIsBasicTasksAvailable(basicTaskCount));
  const [ basicTaskLoading, setBasicTaskLoading ] = useState<boolean>(false);
  const [ basicTaskList, setBasicTaskList ] = useState<Task[]>(initialBasicTaskList);
  const [ basicTaskListValue, setBasicTaskListValue ] = useState<Task[]>(getBasicTaskListValue(basicTaskList));

  const updateBasicTaskList = (count: number): void => {
    setBasicTaskCount(count);
    setBasicTaskLoading(true);
    if (count) {
      taskService
        .getTask({ ...basicFilter, pageSize: count })
        .pipe(
          take(1),
          takeUntil(unsubscribe$)
        )
        .subscribe((data: ICollection<Task>) => {
          const basicTaskList: Task[] = data.elements;
          const basicTaskListValue: Task[] = product === 'privacy'
            ? basicTaskList
            : getAcademyBasicTasks(basicTaskList);
          setBasicTaskList(basicTaskListValue);
          setBasicTaskListValue(getBasicTaskListValue(basicTaskListValue));
          setBasicTaskLoading(false);
        });
    } else {
      setBasicTaskList([]);
      setBasicTaskLoading(false);
    }
  };

  const [ assessmentTaskCount, setAssessmentTaskCount ] = useState<number>(calculateAssessmentTaskCount(initialAssessmentTaskData));
  const [ isAssessmentTasksAvailable, setIsAssessmentTasksAvailable ] = useState<boolean>(getIsAssessmentTasksAvailable(assessmentTaskCount));
  const [ assessmentTaskLoading, setAssessmentTaskLoading ] = useState<boolean>(false);
  const [ assessmentTaskData, setAssessmentTaskData ] = useState<TaskCountDictionary<ITaskCount>>(initialAssessmentTaskData);

  const updateAssessmentTaskData = (count: number): void => {
    setAssessmentTaskCount(count);
    setAssessmentTaskLoading(true);
    if (count) {
      companyDataService
        .getCount()
        .pipe(
          take(1),
          takeUntil(unsubscribe$)
        )
        .subscribe((data: TaskCountDictionary<ITaskCount>) => {
          setAssessmentTaskData(data);
          setAssessmentTaskLoading(false);
        });
    } else {
      setAssessmentTaskData({});
      setAssessmentTaskLoading(false);
    }
  };

  const basicCountValue = product === 'privacy' ? basicTaskCount : basicTaskListValue.length;
  const basicTabHeading = <LabelWithBadge label={ t('tabs.basic') } badge={ basicCountValue } />;
  const assessmentTabHeading = <LabelWithBadge label={ t('tabs.assessment') } badge={ assessmentTaskCount } />;

  const getIsAssetHubTasksAvailable = (taskCount?: number): boolean => product === 'privacy' && !!taskCount && checkPermission(permission, 'assetHubTaskWidget');
  const totalOpenAsset = initialOpenAssetCount?.counts.TOTAL || 0;
  const [ openAssetCount, setOpenAssetCount ] = useState<number>(totalOpenAsset);
  const [ isAssetHubTasksAvailable, setIsAssetHubTasksAvailable ] = useState<boolean>(getIsAssetHubTasksAvailable(totalOpenAsset));
  const [ assetHubTasksLoading, setAssetHubTasksLoading ] = useState<boolean>(false);
  const assetHubTabHeading = <LabelWithBadge label={ t('tabs.assetHub') } badge={ openAssetCount } />;
  const [ assetHubTaskData, setAssetHubTaskData ] = useState<OpenAssetInfo[]>(initialOpenAssetData);

  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    permissionService
      .componentPermissionSubject$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((componentPermissionData: null | ComponentPermissionData): void => {
        if (componentPermissionData) {
          setPermission(componentPermissionData);
        }
      });
    streamService
      .subscribeTaskCountMessage()
      .pipe(takeUntil(unsubscribe$))
      .subscribe((message: TaskCountMessage) => {
        setPayload(message.payload);
      });

    streamService
      .subscribeAssetUpdatedMessage()
      .pipe(takeUntil(unsubscribe$))
      .subscribe(() => {
        assetDashboardService.getOpenAssetCount().subscribe((openAssetCount: OpenAssetCount) => {
          setOpenAssetCount(openAssetCount?.counts?.TOTAL);
          setIsAssetHubTasksAvailable(getIsAssetHubTasksAvailable(openAssetCount?.counts?.TOTAL));
        });
        assetDashboardService.getOpenAssets().subscribe((openAssetData: Collection<OpenAssetInfo>) => {
          setAssetHubTaskData(openAssetData?.elements);
        });
      });
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  const updateOpenAsset = (count: number): void => {
    setOpenAssetCount(count);
    setAssetHubTasksLoading(true);
    if (count) {
      assetDashboardService.getOpenAssetCount().subscribe((openAssetCount: OpenAssetCount) => {
        setOpenAssetCount(openAssetCount?.counts?.TOTAL);
        setIsAssetHubTasksAvailable(getIsAssetHubTasksAvailable(openAssetCount?.counts?.TOTAL));
      });
      assetDashboardService.getOpenAssets().subscribe((openAssetData: Collection<OpenAssetInfo>) => {
        setAssetHubTaskData(openAssetData?.elements);
        setAssetHubTasksLoading(false);
      });
    } else {
      setAssetHubTaskData([]);
      setAssetHubTasksLoading(false);
    }
  };

  useEffect(
    () => {
      const {
        BASIC = 0,
        ASSESSMENT = 0,
        ASSET_HUB = 0
      } = payload;
      if (BASIC !== basicTaskCount) {
        updateBasicTaskList(BASIC);
      } else {
        setBasicTaskListValue(getBasicTaskListValue(basicTaskList));
      }
      if (ASSESSMENT !== assessmentTaskCount) {
        updateAssessmentTaskData(ASSESSMENT);
      }

      if (ASSET_HUB !== openAssetCount) {
        updateOpenAsset(openAssetCount);
      }

      setIsBasicTasksAvailable(getIsBasicTasksAvailable(BASIC));
      setIsAssessmentTasksAvailable(getIsAssessmentTasksAvailable(ASSESSMENT));
      setIsAssetHubTasksAvailable(getIsAssetHubTasksAvailable(ASSET_HUB));
    },
    [ payload, permission, product ]
  );

  const checkAtLeastTwo = (a: boolean, b: boolean, c: boolean): boolean => (a && b) || (b && c) || (a && c);

  if (checkAtLeastTwo(isBasicTasksAvailable, isAssessmentTasksAvailable, isAssetHubTasksAvailable)) {
    return (
      <TaskTabs
        namespace={ namespace }
        tab={ tab }
        changeTabHandler={ changeTabHandler }
        isBasicTabAvailable={ isBasicTasksAvailable }
        basicTabHeading={ basicTabHeading }
        basicTaskList={ basicTaskListValue }
        basicTaskLoading={ assessmentTaskLoading }
        isAssessmentTabAvailable={ isAssessmentTasksAvailable }
        assessmentTabHeading={ assessmentTabHeading }
        assessmentTaskData={ assessmentTaskData }
        assessmentTaskLoading={ assessmentTaskLoading }
        isAssetHubTabAvailable={ isAssetHubTasksAvailable }
        assetHubTabHeading={ assetHubTabHeading }
        assetHubTaskData={ assetHubTaskData }
        assetHubTaskLoading={ assetHubTasksLoading }
      />
    );
  }
  else if (isAssetHubTasksAvailable) {
    return (
      <Widget
        name="assetHubTaskWidget"
        permission={ permission }
        title={ assetHubTabHeading }
      >
        <AssetHubTasks
          namespace={ namespace }
          assetHubTaskData={ assetHubTaskData }
          loading={ assetHubTasksLoading }
        />
      </Widget>
    );
  }
  else if (isAssessmentTasksAvailable) {
    return (
      <Widget
        name="assessmentTasksWidget"
        permission={ permission }
        title={ assessmentTabHeading }
      >
        <AssessmentTasks
          namespace={ namespace }
          assessmentTaskData={ assessmentTaskData }
          loading={ assessmentTaskLoading }
        />
      </Widget>
    );
  } else {
    if (tab === TaskCount.ASSESSMENT || tab === TaskCount.ASSET_HUB) {
      changeTabHandler(TaskCount.BASIC);
    }

    if (checkPermission(permission, 'basicTasksWidget')) {
      return (
        <Widget
          name="basicTasksWidget"
          permission={ permission }
          title={ basicTabHeading }
        >
          <BasicTasks
            namespace={ namespace }
            basicTaskList={ basicTaskListValue }
            loading={ basicTaskLoading }
          />
        </Widget>
      );
    }
  }
  return null;
};

export default TasksWidget;
