// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".heading-AssetHubTasks_xADB5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:space-between;-ms-justify-content:space-between;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;margin:13px 0;padding:0 16px}.taskName-AssetHubTasks_fd8QS{font-weight:500;font-size:14px;line-height:18px;color:#4f4f4f}.taskList-AssetHubTasks_E2I_G{width:100%;list-style:none;margin:0;padding:0;box-shadow:0 3px 8px rgba(0,0,0,0.09)}.taskItem-AssetHubTasks_hTwEV{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;-ms-justify-content:space-between;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;width:100%;height:49px;padding:8px 24px;box-sizing:border-box;border-bottom:1px solid #e0e0e0}.taskItem-AssetHubTasks_hTwEV:last-child{border-bottom:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "heading-AssetHubTasks_xADB5",
	"taskName": "taskName-AssetHubTasks_fd8QS",
	"taskList": "taskList-AssetHubTasks_E2I_G",
	"taskItem": "taskItem-AssetHubTasks_hTwEV"
};
export default ___CSS_LOADER_EXPORT___;
