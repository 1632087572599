import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { Collection, CollectionParameters, ComponentListType, FunctionalArea, OpenAssetInfo, TeamArea, TeamUser } from '@proliance-ai/typings';
import { TaskTypeEncode } from '@interfaces';
import { camelCaseToKebabCase, Case, kebabCaseToSnakeCase } from '@proliance-ai/design-system';
import {
  academyService,
  activityFilter,
  activityService,
  assetDashboardService,
  assetManagementResolver,
  basicFilter,
  companyDataService,
  dashboardService,
  DataBreach,
  dataBreachService,
  DataBreachType,
  documentService,
  DocumentType,
  getDataBreachInitialState,
  getRiskInitialState,
  IActivity,
  IRiskCase,
  ISurveyParams,
  newsResolver,
  riskService,
  RiskState,
  RiskType,
  surveyService,
  systemsService,
  Task,
  TaskCount,
  taskService,
  teamService,
  userService
} from '@services';
import { checkPermission, getAcceptedRouteTabList, ResolverData, routeTabHandler } from '@router';
import { getFilterParameters } from '@shared/utilities';
import { AnalyticsTabsKeys } from '@pages/Analytics/components/Analytics';
import { systemsInitialState } from '@pages/SystemsList/components';

const getResolverDataParameter = (key: string) => (data: ResolverData) => of(data.parameter[key]);
const getResolverData = (key: keyof ResolverData) => (data: ResolverData) => of(data[key]);

const assetManagementParams = {
  routeParameters: assetManagementResolver,
  openAssetCount: (data: ResolverData) => checkPermission('assetManagement', data.permission) ? assetDashboardService.getOpenAssetCount() : of(null)
};

export const routeResolver = {
  error: (statusCode: number = 404) => ({
    errorCode: () => of(`${ statusCode }`)
  }),
  documentError: {
    type: getResolverDataParameter('type'),
    context: getResolverDataParameter('context'),
    id: getResolverDataParameter('id')
  },

  common: {
    userData: () => userService.user$.pipe(take(1))
  },

  dpoNomination: {
    surveyData: (data: ResolverData) => !!Object.keys(data.parameter).length
      ? surveyService.getSurveyData({
        ...(data.parameter as unknown as ISurveyParams),
        type: TaskTypeEncode.DPO_NOMINATION
      })
      : surveyService.getSurveyByName(camelCaseToKebabCase(TaskTypeEncode.DPO_NOMINATION, Case.lower))
  },
  dpoPrivacyPolicy: {
    surveyData: (data: ResolverData) => surveyService.getSurveyData({
      ...((data.parameter as unknown) as ISurveyParams),
      type: TaskTypeEncode.DPO_PRIVACY_POLICY
    })
  },
  dpoNotification: {
    surveyData: (data: ResolverData) => surveyService.getSurveyData({
      ...((data.parameter as unknown) as ISurveyParams),
      type: TaskTypeEncode.DPO_NOTIFICATION
    })
  },

  customProcesses: (isExisting: boolean = true) => ({
    id: getResolverDataParameter('id'),
    functionalArea: (data: ResolverData) =>
      of(kebabCaseToSnakeCase(data.parameter.functionalArea, Case.upper)),
    customProcessesData: (data: ResolverData) => {
      const id = data.parameter.id;
      const functionalArea = kebabCaseToSnakeCase(data.parameter.functionalArea, Case.upper);
      return surveyService.getCustomProcess(id ? { id, functionalArea } : { functionalArea });
    },
    isExisting: () => of(isExisting)
  }),
  processSelection: {
    functionalArea: (data: ResolverData) =>
      of(kebabCaseToSnakeCase(data.parameter.functionalArea, Case.upper)),
    processSelectionData: (data: ResolverData) =>
      surveyService.getSurveyByFunctionalArea({
        functionalArea: kebabCaseToSnakeCase(data.parameter.functionalArea, Case.upper)
      })
  },
  surveySystemAssignment: () => ({
    id: getResolverDataParameter('id'),
    params: (data: ResolverData) => of({ ...data.parameter, type: 'processContext' }),
    surveyData: (data: ResolverData) => data.parameter.error
      ? throwError({ status: data.parameter.error })
      : surveyService
        .getSurveyData({
          ...((data.parameter as unknown) as ISurveyParams),
          type: 'processContext',
          showLastResult: true,
          forceReadonly: false
        }),
    showLastResult: () => of(false),
    forceReadonly: () => of(false)
  }),
  surveyTask: (showLastResult: boolean = false, forceReadonly: boolean = false) => ({
    id: getResolverDataParameter('id'),
    type: getResolverDataParameter('type'),
    params: getResolverData('parameter'),
    surveyData: (data: ResolverData) => data.parameter.error
      ? throwError({ status: data.parameter.error })
      : surveyService
        .getSurveyData({
          ...((data.parameter as unknown) as ISurveyParams),
          showLastResult,
          forceReadonly
        }),
    showLastResult: () => of(showLastResult),
    forceReadonly: () => of(forceReadonly)
  }),

  dashboard: {
    routeTab: (data: ResolverData) => routeTabHandler<TaskCount>('dashboard', data),
    basicTaskData: (data: ResolverData) => checkPermission('basicTasksWidget', data.permission)
      ? taskService
        .getTask({ ...basicFilter, pageSize: 1000 })
        .pipe(
          map((collection: Collection<Task>) => {
            const {
              elements: basicTaskList,
              totalResult: basicTaskCount
            } = collection;
            return {
              basicTaskList,
              basicTaskCount
            };
          })
        )
      : of({
        basicTaskList: [],
        basicTaskCount: 0
      }),
    assessmentTaskData: (data: ResolverData) =>
      checkPermission('assessmentTasksWidget', data.permission)
        ? companyDataService.getCount()
        : of({}),
    complianceScore: (data: ResolverData) =>
      checkPermission('complianceScoreWidget', data.permission) && !checkPermission('areaWidget', data.permission, 'demo')
        ? dashboardService.getComplianceScore()
        : of(null),
    activityList: (data: ResolverData) =>
      checkPermission('activitiesWidget', data.permission)
        ? activityService.getActivity(activityFilter).pipe(map((collection: Collection<IActivity>) => collection.elements))
        : of(null),
    functionalAreaScore: (data: ResolverData) =>
      checkPermission('areaWidget', data.permission, 'demo')
        ? dashboardService.getDetailedComplianceScore()
        : of(null),
    newsList: (data: ResolverData) =>
      checkPermission('areaWidget', data.permission, 'demo')
        ? of([])
        : newsResolver(),
    openAssetCount: (data: ResolverData) => checkPermission('assetHubTaskWidget', data.permission)
      ? assetDashboardService.getOpenAssetCount()
      : of(null),
    openAssetData: (data: ResolverData) => checkPermission('assetHubTaskWidget', data.permission)
      ? assetDashboardService.getOpenAssets().pipe(map((collection: Collection<OpenAssetInfo>) => collection.elements))
      : of([])
  },

  analytics: {
    routeTab: (data: ResolverData) => routeTabHandler<AnalyticsTabsKeys>('analytics', data)
  },

  processes: {
    userData: () => teamService.getTeam(),
    assessmentTaskData: () => companyDataService.getCount(),
    selectableFunctionalArea: () => companyDataService.getSelectableFunctionalArea(),
    routeTab: (data: ResolverData) => routeTabHandler<FunctionalArea>('processes', data),
    openTasksValue: (data: ResolverData) => of(!!data.parameter.showOpenTasks)
  },

  tom: {
    openTasksValue: (data: ResolverData) => of(!!data.parameter.showOpenTasks)
  },

  risk: {
    activeCount: () => riskService.getActiveRiskCount(),
    routeTab: (data: ResolverData) => routeTabHandler<RiskState>('risk', data),
    riskCollection: (data: ResolverData): Observable<Collection<IRiskCase>> => {
      const routeTab = data.parameter.routeTab;
      const type = routeTab
        ? routeTab.toUpperCase() as RiskState
        : RiskState.ACTIVE;
      const state = getRiskInitialState(type);
      const { pageIndex = 0, pageSize, sortBy } = state;
      const page = pageIndex + 1;
      const sortField = sortBy && sortBy[0]
        ? sortBy[0].id
        : undefined;
      const sortDirection = sortBy && sortBy[0]
        ? sortBy[0].desc
          ? 'desc'
          : 'asc'
        : undefined;
      const filters = [ {
        id: 'state',
        value: type
      } ];
      const parameters: CollectionParameters = {
        page,
        pageSize,
        sortField,
        sortDirection,
        ...getFilterParameters(filters)
      };
      return riskService.getRiskCollection(parameters);
    }
  },

  riskSurvey: (isExisting: boolean = true, showLastResult: boolean = false, forceReadonly: boolean = false) => ({
    id: getResolverDataParameter('id'),
    riskData: (data: ResolverData) => {
      const { id, riskType = RiskType.PRE_ASSESSMENT } = data.parameter;
      const parameters = id ? { id } : undefined;
      return riskService.getRisk(
        kebabCaseToSnakeCase(riskType, Case.upper) as RiskType,
        {
          ...parameters,
          showLastResult,
          forceReadonly
        }
      );
    },
    isExisting: () => of(isExisting)
  }),

  document: {
    routeTab: (data: ResolverData) => routeTabHandler<DocumentType>('document', data),
    documentCollection: (data: ResolverData) => {
      const routeTab = data.parameter.routeTab
        ? kebabCaseToSnakeCase(data.parameter.routeTab, Case.upper) as DocumentType
        : null;
      const acceptedRouteTabList: DocumentType[] = getAcceptedRouteTabList<DocumentType>('document', data.permission);
      const firstTab = acceptedRouteTabList.shift();
      return !routeTab || routeTab === firstTab
        ? documentService.getDocumentCollection({
          filters: [ {
            id: 'type',
            value: firstTab
          } ]
        })
        : acceptedRouteTabList.includes(routeTab)
          ? documentService.getDocumentCollection({
            filters: [ {
              id: 'type',
              value: routeTab
            } ]
          })
          : throwError({ status: 404 });
    }
  },

  dpo: {
    dpo: () => teamService.getDpo()
  },

  staticPage: (usePermission: null | ComponentListType = null) => ({
    usePermission: () => of(usePermission)
  }),

  staticPageInfo: (showInfoBlock: boolean = true) => ({
    showInfoBlock: () => of(showInfoBlock)
  }),

  dataBreach: {
    activeCount: () => dataBreachService.getActiveDataBreachCount(),
    routeTab: (data: ResolverData) => routeTabHandler<DataBreachType>('dataBreach', data),
    dataBreachCollection: (data: ResolverData): Observable<Collection<DataBreach>> => {
      const routeTab = data.parameter.routeTab;
      const type = routeTab
        ? routeTab.toUpperCase() as DataBreachType
        : DataBreachType.ACTIVE;
      const state = getDataBreachInitialState(type);
      const { pageIndex = 0, pageSize, sortBy } = state;
      const page = pageIndex + 1;
      const sortField = sortBy && sortBy[0] ? sortBy[0].id : undefined;
      const sortDirection = sortBy && sortBy[0] ? (sortBy[0].desc ? 'desc' : 'asc') : undefined;
      const filters = [ {
        id: 'status',
        value: type
      } ];
      const parameters: CollectionParameters = {
        page,
        pageSize,
        sortField,
        sortDirection,
        ...getFilterParameters(filters)
      };
      return dataBreachService.getDataBreachCollection(parameters);
    }
  },

  dataBreachSurvey: (isExisting: boolean = true, showLastResult: boolean = false, forceReadonly: boolean = false) => ({
    id: getResolverDataParameter('id'),
    dataBreachData: (data: ResolverData) => {
      const id = data.parameter.id;
      const parameters = id ? { id } : undefined;
      return dataBreachService.getDataBreach({
        ...parameters,
        showLastResult,
        forceReadonly
      });
    },
    isExisting: () => of(isExisting)
  }),

  myCourses: {
    courseData: () => academyService.getUserCourseList()
  },

  courseManagement: {
    courseData: () => {
      return academyService.getCompanyCourse();
    }
  },

  team: {
    teamData: () => teamService.getTeam()
  },

  teamDetails: {
    teamArea: (data: ResolverData) => of(kebabCaseToSnakeCase(data.parameter.teamArea, Case.upper)),
    mainUser: (data: ResolverData) => {
      const teamArea = kebabCaseToSnakeCase(data.parameter.teamArea, Case.upper) as TeamArea;
      return teamService.getTeamByFunctionalArea(teamArea)
        .pipe(
          mergeMap((teamUser: null | TeamUser): Observable<TeamUser> => !teamUser
            ? throwError({ status: 404 })
            : of(teamUser)
          ));
    }
  },

  systems: {
    systems: () => systemsService.getSystemsStatistics()
  },
  systemsList: {
    id: getResolverDataParameter('id'),
    url: getResolverDataParameter('url'),
    systemsCollection: (data: ResolverData) => {
      const systemsType = systemsService.getSystemsType(data.parameter.url);
      if (systemsType === null) {
        return of(null);
      }
      return systemsService
        .getSystemsByType(
          data.parameter.url,
          systemsInitialState[systemsType]
        );
    }
  },
  systemsAdd: {
    id: getResolverDataParameter('id'),
    url: getResolverDataParameter('url'),
    systemsType: (data: ResolverData) => of(systemsService.getSystemsType(data.parameter.url)),
    systems: (data: ResolverData) => {
      const type = systemsService.getSystemsType(data.parameter.url);
      return type === null ? of(null) : surveyService.getSurveyBySystemsType(type);
    }
  },
  systemsDetails: {
    id: getResolverDataParameter('id'),
    url: getResolverDataParameter('url'),
    systemsType: (data: ResolverData) => of(systemsService.getSystemsType(data.parameter.url)),
    systems: (data: ResolverData) => {
      const type = systemsService.getSystemsType(data.parameter.url);
      const id = data.parameter.id;
      const params: Record<string, string> = {
        params: JSON.stringify({ id })
      };
      return type === null ? of(null) : surveyService.getSurveyBySystemsType(type, params);
    }
  },
  assetManagement: assetManagementParams,
  assetManagementTab: assetManagementParams,
  assetManagementTabAsset: assetManagementParams,
  assetManagementTabAssetSidebar: assetManagementParams,
  assetManagementTabAssetSidebarTab: assetManagementParams
};
