import { permissionService } from '@services';
import { getRouterDataForPagePermission, getTabRouteName } from '@router';
import { RouteListType } from '@router/configuration';

const checkPathPermission = (path: string): boolean => {
  const permission = permissionService.pagePermissionSubject$.value as Record<string, string>;
  if (!permission) {
    return false;
  }
  const routeDataForPagePermission = getRouterDataForPagePermission(path);
  if (!routeDataForPagePermission) {
    return false;
  }
  if (routeDataForPagePermission.name === 'default') {
    return true;
  }
  const routeName = getTabRouteName(routeDataForPagePermission.name as RouteListType);
  return !!permission[routeName];
};

export default checkPathPermission;
