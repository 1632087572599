import { Observable } from 'rxjs';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Collection } from '@proliance-ai/typings';
import { TaskTypeGroup, TaskTypeType } from '@interfaces';

export type TaskReturnType = keyof typeof TaskTypeGroup | undefined;

export enum TaskCount {
  BASIC = 'BASIC',
  ASSESSMENT = 'ASSESSMENT',
  ASSET_HUB = 'ASSET_HUB',
}
export type TaskTabType = keyof typeof TaskCount;
export type TaskTabDictionary<T> = {
  [key in TaskTabType]?: T;
};

export interface Task {
  id: string;
  userId: number;
  companyId: number;
  name: string;
  functionalArea: string;
  type: TaskTypeType;
  status: 'OPEN' | 'CLOSED';
  closeType: string;
  updatedAt: string;
  createdAt: string;
  parameters?: Record<string, any>;
  link: string;
}

export interface TaskService {
  getTask: (parameters?: Record<string, any>) => Observable<Collection<Task>>;
  getTaskIcon: (type: TaskTypeType | TaskTypeGroup.TOM) => null | IconDefinition;
}
