import { AssetManagementEvidences, Country, Evidence, SortDirection } from '@proliance-ai/typings';
import { sortObjectsArray } from '@proliance-ai/utilities';
import { assetManagementService, i18n } from '@services';
import { documentsMapper } from './documentsMapper';

export const evidencesMapper = async (assetManagementEvidences: AssetManagementEvidences): Promise<AssetManagementEvidences> => {
  const { t } = i18n;
  let { evidences = [] } = assetManagementEvidences;
  evidences = evidences
    .map((evidence: Evidence) => ({
      ...evidence,
      ...documentsMapper(evidence)
    }));

  const sortingConditions = [
    {
      path: 'country',
      sortDirection: SortDirection.asc
    },
    {
      path: 'type',
      sortDirection: SortDirection.asc
    }
  ];

  const countryList: Country[] = [
    ...new Set(
      evidences
        .map((evidence: Evidence) => evidence.country)
        .filter((country): country is Country => !!country)
    )
  ];

  const promiseList: Array<Promise<Record<Country, string>>> = countryList
    .map(async (country: Country): Promise<Record<Country, string>> => {
      const value: string = await assetManagementService.getCountryTitle(country);
      return { [country]: value } as Record<Country, string>;
    });

  const countryTitleData: Array<Record<Country, string>> = await Promise.all(promiseList);
  const countryNameList: Record<Country, string> = Object.values(countryTitleData)
    .reduce(
      (result: Record<string, string>, item: Record<string, string>): Record<string, string> => ({
        ...result,
        ...item
      }),
      {} as Record<string, string>
    );

  const getValue = (evidence: Evidence, path?: string | string[]): undefined | string => {
    const { country, type } = evidence;
    const countryName = countryNameList[country as Country];
    if (path === 'country') {
      return countryName;
    }
    if (path === 'type') {
      return t(`assetManagement:evidences.${ type }.name`);
    }
    return;
  };
  
  const sorted = sortObjectsArray<Evidence>(
    evidences,
    sortingConditions,
    getValue
  );
  
  return {
    evidences: sorted
  };
};
