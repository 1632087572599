import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseModalButton, modalHandler, ModalOptions, modalService } from '@proliance-ai/react-ui';
import { isEmpty, snakeCaseToCamelCase } from '@proliance-ai/utilities';
import { Button } from '@proliance-ai/design-system';
import { SystemEntityData } from '@services';
import { UseSystemAssetModalOptions, UseSystemAssetModalReturn } from './useSystemAssetModal.typings';

export const useSystemAssetModal = <E extends SystemEntityData, D extends object>(options: UseSystemAssetModalOptions<E, D>): UseSystemAssetModalReturn<E> => {
  const {
    tab = '',
    textNamespace = 'common',
    formNamespace = 'form',
    buttonTranslationKey = 'common:button',
    translationKey,
    FormComponent,
    onOpen,
    onClose,
    callback,
    dataSource,
    createMethod,
    updateMethod
  } = options;

  const id = snakeCaseToCamelCase(tab);

  const { t } = useTranslation(textNamespace);
  const [ form, setForm ] = useState<null | HTMLFormElement>(null);
  const [ isDirty, setIsDirty ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  useEffect(
    () => {
      if (form) {
        modalService
          .patchModalOptions({
            id,
            footer: {
              actionsElement: getActionsElement()
            }
          });
      }
    },
    [ form, isLoading, isDirty ]
  );

  const getActionsElement = () => {
    const submit = (): void => {
      if (form !== null) {
        form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    };
    const onClick = typeof onClose === 'function'
      ? modalHandler(onClose)
      : undefined;

    const cancelButtonDataAttributesDictionaryValue = { ahCancelButton: tab };
    const cancelButtonDataAttributesDictionary = {
      test: cancelButtonDataAttributesDictionaryValue,
      guide: cancelButtonDataAttributesDictionaryValue
    };
    const closeButton = (
      <CloseModalButton
        dataAttributesDictionary={ cancelButtonDataAttributesDictionary }
        onClick={ onClick }
      />
    );
    const dataAttributesDictionaryValue = { ahSubmitButton: tab };
    const dataAttributesDictionary = {
      test: dataAttributesDictionaryValue,
      guide: dataAttributesDictionaryValue
    };
    const saveButton = (
      <Button
        dataAttributesDictionary={ dataAttributesDictionary }
        preset="primary"
        text={ t(`${ buttonTranslationKey }.save`) }
        disabled={ isLoading || !isDirty }
        onClick={ submit }
      />
    );
    return (
      <>
        { closeButton }
        { saveButton }
      </>
    );
  };

  const showModal = (
    entity: E = {} as E
  ): void => {
    const { title } = entity;
    const titleKey = isEmpty(entity)
      ? 'add'
      : 'edit';
    const modalOptions: ModalOptions = {
      id,
      size: 'large',
      dataAttributesDictionary: {
        test: { businessPremise: 'modal' },
        guide: { businessPremise: 'modal' }
      },
      heading: {
        closeHandler: modalHandler(onClose),
        title: t(`${ translationKey }.title.${ titleKey }`, { title })
      },
      content: {
        contentElement: (
          <FormComponent
            textNamespace={ textNamespace }
            formNamespace={ formNamespace }
            translationKey={ translationKey }
            buttonTranslationKey={ buttonTranslationKey }
            entity={ entity }
            setForm={ setForm }
            setIsLoading={ setIsLoading }
            setIsDirty={ setIsDirty }
            callback={ callback }
            onClose={ onClose }
            dataSource={ dataSource }
            createMethod={ createMethod }
            updateMethod={ updateMethod }
          />
        ),
        usePadding: false
      },
      footer: {
        actionsElement: getActionsElement()
      }
    };
    if (typeof onOpen === 'function') {
      onOpen(entity);
    }
    modalService.show(modalOptions);
  };

  return { showModal };
};
