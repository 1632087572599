import React, { FC, ReactElement } from 'react';
import { Badge } from '@proliance-ai/design-system';

interface Props {
  label: string;
  badge: any;
}

const LabelWithBadge: FC<Props> = ({ label, badge }): ReactElement => {
  return (
    <Badge
      content={badge}
      color="danger"
      isRounded={true}
    >
      {label}
    </Badge>
  );
};

export default LabelWithBadge;
