import { useMemo } from 'react';
import { Column } from 'react-table';
import { TFunction } from 'i18next';
import { Systems, SystemsEntity, SystemsType } from '@services';
import getAssociatedCompaniesColumnList from './getAssociatedCompaniesColumnList';
import getSoftwareColumnList from './getSoftwareColumnList';
import getServiceProviderColumnList from './getServiceProviderColumnList';
import getFreelancerColumnList from './getFreelancerColumnList';
import getDataStorageSelfHostedColumnList from './getDataStorageSelfHostedColumnList';
import getDataStorageThirdPartyHostedColumnList from './getDataStorageThirdPartyHostedColumnList';
import getDataStorageCloudHostedColumnList from './getDataStorageCloudHostedColumnList';
import { getWebsiteColumnList, getPrincipalColumnList, SystemAssetColumnsOptions } from '@shared/hooks';

export interface ColumnsOptions {
  t: TFunction;
  systemsType: SystemsType;
}

export const getColumns = ({
  t,
  systemsType,
  isLoadingState,
  callback,
  permission
}: SystemAssetColumnsOptions): Array<Column<SystemsEntity>> => {
  switch (systemsType) {
    case Systems.ASSOCIATED_COMPANY:
      return useMemo(
        () => getAssociatedCompaniesColumnList({ t, systemsType }) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.WEBSITE:
      return useMemo(
        () => getWebsiteColumnList({ t, systemsType, isLoadingState, callback, permission }) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.SOFTWARE:
      return useMemo(
        () => getSoftwareColumnList({ t, systemsType }) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.SERVICE_PROVIDER:
      return useMemo(
        () => getServiceProviderColumnList({ t, systemsType }) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.PRINCIPAL:
      return useMemo(
        () => getPrincipalColumnList({ t, systemsType, isLoadingState, callback, permission }) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.FREELANCER:
      return useMemo(
        () => getFreelancerColumnList({ t, systemsType }) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.DATA_STORAGE_SELF_HOSTED:
      return useMemo(
        () => getDataStorageSelfHostedColumnList({ t, systemsType }) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.DATA_STORAGE_THIRD_PARTY_HOSTED:
      return useMemo(
        () => getDataStorageThirdPartyHostedColumnList({ t, systemsType }) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.DATA_STORAGE_CLOUD_HOSTED:
      return useMemo(
        () => getDataStorageCloudHostedColumnList({ t, systemsType }) as Array<Column<SystemsEntity>>,
        []
      );
    default:
      return [];
  }
};
