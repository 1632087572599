import React, { FC } from 'react';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { TaskType } from '@interfaces';
import { redirect } from '@router';
import { encodeTaskType, Task } from '@services';
import { Button, LinkButton, Loading, useDataAttributes } from '@proliance-ai/design-system';

import styles from './BasicTasks.styl';

interface Props {
  namespace: Namespace;
  basicTaskList: Task[];
  loading: boolean;
}

const BasicTasksWidget: FC<Props> = ({
  namespace,
  basicTaskList,
  loading
}) => {
  const { t } = useTranslation(namespace);

  const loadingElement = (
    <li className={ styles.loading }>
      <Loading/>
    </li>
  );

  const emptyElement = <li className={ styles.empty }>{ t('empty') }</li>;

  const basicTaskClickHandler = (task: Task) => () => {
    switch (task.type) {
      case TaskType.ACADEMY:
        break;
      case TaskType.DPO_NOMINATION:
        redirect('dpoNomination', { type: encodeTaskType(task.type), id: task.id });
        break;
      case TaskType.DPO_PRIVACY_POLICY:
        redirect('dpoPrivacyPolicy', { type: encodeTaskType(task.type), id: task.id });
        break;
      case TaskType.DPO_NOTIFICATION:
        redirect('dpoNotification', { type: encodeTaskType(task.type), id: task.id });
        break;
      case TaskType.DEFINE_TEAM:
        redirect('team');
        break;
      case TaskType.ACADEMY_INVITE_EMPLOYEE:
        redirect('courseManagement');
        break;
      case TaskType.ASSET_CREATION_COMPLETED:
        redirect('assetManagementTab', { routeTab: encodeTaskType(task?.parameters?.assetType) });
        break;
      case TaskType.REVIEW_MIGRATED_ASSETS:
        redirect('assetManagementTab');
        break;
      default:
        redirect('surveyTask', { type: encodeTaskType(task.type), id: task.id });
    }
  };

  const isEmpty = !basicTaskList.length;
  const getBasicTaskAction = (task: Task) => task.type === 'ACADEMY'
    ? (
      <LinkButton
        preset="primary"
        size="small"
        href={ task.link }
        text={ t('common:button.open') }
      />
    )
    : (
      <Button
        preset="primary"
        size="small"
        text={ t('common:button.open') }
        onClick={ basicTaskClickHandler(task) }
      />
    );

  const basicTasks = loading
    ? loadingElement
    : isEmpty
      ? emptyElement
      : basicTaskList.map((task: Task) => (
        <li
          key={ task.id }
          className={ styles.taskItem }
        >
          <span
            className={ styles.taskName }
            { ...useDataAttributes({ test: { 'basic-tasks': task.type } }).dataAttributes }
          >
            { task.name }
          </span>
          { getBasicTaskAction(task) }
        </li>
      ));

  return (
    <ul className={ styles.taskList }>
      { basicTasks }
    </ul>
  );
};

export default BasicTasksWidget;
