import React, { FC, useRef, useState, useEffect, memo, MouseEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoute } from 'react-router5';
import { StateMeta } from 'router5/dist/types/base';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { ContextMenuItem, CurrentUser } from '@proliance-ai/typings';
import { Callback, useDataAttributes } from '@proliance-ai/design-system';
import { ContextMenu, useOutsideClick } from '@proliance-ai/react-ui';
import { getUserTitle } from '@proliance-ai/utilities';
import { useLobbyRedirect } from '@hooks';
import { redirectToLobby } from '@router';
import { userService, permissionService, PagePermissionData, i18n } from '@services';
import { useShowCustomerRequestModal } from '@layout/AppLayout/components/UserMenu/hooks';
import { CardAvatar } from '@shared/components';

import { faArrowRightFromBracket, faQuestionCircle, faUser, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import styles from './UserMenu.styl';

const UserMenu: FC = (): null | ReactElement => {
  const { t } = useTranslation();
  const { route, router } = useRoute();
  const currentRouteName = route.name;

  const { redirect } = useLobbyRedirect();

  const isCancelled = useRef(false);

  const getAction = (callback: Callback): void => {
    const lifecycleFunctions = router.getLifecycleFunctions();
    const canDeactivateDictionary = lifecycleFunctions[0];
    const canDeactivateFunction = canDeactivateDictionary[currentRouteName];
    if (canDeactivateFunction) {
      const state = router.getState();
      if (!state.meta) {
        state.meta = {} as StateMeta;
      }
      state.meta.params.callback = callback;
      const doneFn = () => {
        return;
      };
      canDeactivateFunction(state, state, doneFn);
    } else {
      callback();
    }
  };

  const profileMenuItem: ContextMenuItem = {
    id: 'profile',
    content: (
      <>
        <FontAwesomeIcon
          icon={ faUser }
          title={ t('userMenu.profile') }
        />
        { t('userMenu.profile') }
      </>
    ),
    action: () => getAction(() => redirect({ route: 'profile' }))
  };
  const helpCenterUrl = (window as any).env.helpCenterUrl;
  const helpCenterMenuItem: ContextMenuItem = {
    id: 'helpCenter',
    content: (
      <>
        <FontAwesomeIcon
          icon={ faQuestionCircle }
          title={ t('userMenu.helpCenter') }
        />
        { t('userMenu.helpCenter') }
      </>
    ),
    action:  () => window.open(helpCenterUrl, '_blank')
  };
  const { showModal } = useShowCustomerRequestModal();
  (window as any).showCustomerRequestModal = showModal;
  const contactUsMenuItem: ContextMenuItem = {
    id: 'customerRequest',
    content: (
      <>
        <FontAwesomeIcon
          icon={ faEnvelope }
          title={ t('userMenu.customerRequest') }
        />
        { t('userMenu.customerRequest') }
      </>
    ),
    action: showModal
  };
  const logoutMenuItem: ContextMenuItem = {
    id: 'logout',
    content: (
      <>
        <FontAwesomeIcon
          icon={ faArrowRightFromBracket }
          title={ t('userMenu.logout') }
        />
        { t('userMenu.logout') }
      </>
    ),
    action: () => getAction(() => redirectToLobby())
  };

  const [ menuItems, setMenuItems ] = useState([ logoutMenuItem ]);
  const [ user, setUser ] = useState<null | CurrentUser>(null);
  const [ profilePagePermission, setProfilePagePermission ] = useState<boolean>(false);
  const [ showMenu, setShowMenu ] = useState(false);
  const hideMenu = () => setShowMenu(false);
  const toggleMenu = () => setShowMenu(!showMenu);
  const { ref } = useOutsideClick<HTMLDivElement>(hideMenu);

  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    userService
      .user$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value: null | CurrentUser) => {
        if (isCancelled.current) {
          return;
        }
        if (value !== null) {
          setUser(value);
        }
      });

    permissionService.pagePermissionSubject$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((pagePermissionData: null | PagePermissionData) => {
        setProfilePagePermission(!!pagePermissionData && !!pagePermissionData.profile);
      });
    return () => {
      isCancelled.current = true;
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  useEffect((): void => {
    const defaultMenuItems: ContextMenuItem[] = [
      helpCenterMenuItem,
      contactUsMenuItem,
      logoutMenuItem
    ];
    setMenuItems(
      profilePagePermission
        ? [ profileMenuItem, ...defaultMenuItems ]
        : defaultMenuItems
    );
  }, [ profilePagePermission, i18n.language ]
  );

  if (!user) {
    return null;
  }

  const chevronClassName = showMenu ? `${ styles.chevron } ${ styles.opened }` : styles.chevron;

  const { firstName, lastName, impersonatedBy } = user;
  const avatar = (
    <CardAvatar
      className={ styles.icon }
      user={ user }
    />
  );
  const chevron = (
    <FontAwesomeIcon
      className={ chevronClassName }
      icon={ faChevronDown }
    />
  );
  const menuHeading = impersonatedBy
    ? (
      <>
        <div className={ styles.dark }>{ `${ impersonatedBy.firstName } ${ impersonatedBy.lastName }` }</div>
        <div className={ styles.light }>{ `${ t('userMenu.asUser') } ${ firstName } ${ lastName }` }</div>
      </>
    )
    : (
      <>
        <div className={ styles.light }>{ firstName }</div>
        <div className={ styles.dark }>{ lastName }</div>
      </>
    );
  const onClick = (event: MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  };
  const menu = showMenu
    ? (
      <ContextMenu
        active={ currentRouteName }
        className={ styles.contextMenu }
        heading={ menuHeading }
        itemList={ menuItems }
        onClick={ onClick }
      />
    )
    : null;

  const { dataAttributes } = useDataAttributes({
    test: { profile: 'menu' },
    guide: { profile: 'menu' }
  });

  const title = getUserTitle(t, user);
  return (
    <div
      ref={ ref }
      className={ `${ styles.userMenu } no-select` }
      onClick={ toggleMenu }
      { ...dataAttributes }
    >
      <div
        className={ styles.container }
        title={ title }
      >
        { avatar }
        { chevron }
      </div>
      { menu }
    </div>
  );
};

export default memo(UserMenu);
