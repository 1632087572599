import React, { ReactNode } from 'react';
import { Cell } from 'react-table';
import { TFunction } from 'i18next';
import { AjaxError } from 'rxjs/ajax';
import { take } from 'rxjs/operators';
import { Callback, ComponentPermissionData } from '@proliance-ai/typings';
import { modalService, notificationService } from '@proliance-ai/react-ui';
import { capitalize } from '@proliance-ai/utilities';
import { Button, UseStateReturn } from '@proliance-ai/design-system';
import { systemsService, SystemType } from '@services';
import {
  default as SystemDeleteErrorModalContent
} from './SystemDeleteErrorModalContent/SystemDeleteErrorModalContent';

interface ActionsCellProps {
  t: TFunction;
  className?: string;
  translationKey: string;
  systemsType: SystemType;
  isLoadingState: UseStateReturn<boolean>;
  callback?: Callback;
  permission: ComponentPermissionData;
}

export const SystemAssetModalActionsCell = ({
  t,
  className,
  translationKey,
  systemsType,
  isLoadingState,
  callback,
  permission
}: ActionsCellProps) => <T extends { id: string }>(cell: Cell<T>): null | ReactNode => {
  const { row } = cell;
  const { id, title } = row.original;
  const isHasPermission = permission?.assetManagement?.write;

  const [ isLoading, setIsLoading ] = isLoadingState;

  const onDelete = (): void => {
    setIsLoading(true);
    systemsService.deleteSystem(systemsType, id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          const dataAttributesDictionary = {
            test: { notificationSuccess: 'deleteSystem' },
            guide: { notificationSuccess: 'deleteSystem' }
          };
          notificationService.success({
            textTranslationKey: `${ translationKey }.message.deleteSuccess`,
            dataAttributesDictionary
          });
          if (typeof callback === 'function') {
            callback();
          }
          setIsLoading(false);
        },
        error: (error: AjaxError) => {
          setIsLoading(false);
          const id = `delete${ capitalize(systemsType) }Error`;
          modalService.show({
            id,
            size: 'large',
            dataAttributesDictionary: {
              test: { [id]: 'modal' },
              guide: { [id]: 'modal' }
            },
            heading: {
              title: t(`${ translationKey }.error.title`)
            },
            content: {
              contentElement: (
                <SystemDeleteErrorModalContent
                  translationKey={ `${ translationKey }.error` }
                  error={ error.response }
                />
              )
            }
          });
        }
      });
  };
  const dataAttributesDictionary = {
    test: { deleteButton: id },
    guide: { deleteButton: id }
  };

  const text = t('common:button.delete');
  const warningTitle = t(`${ translationKey }.title`);
  const warningText = t(`${ translationKey }.text`, { title });

  return (
    <div className={ className }>
      <Button
        dataAttributesDictionary={ dataAttributesDictionary }
        text={ text }
        warningTitle={ warningTitle }
        warningText={ warningText }
        preset="danger"
        size="small"
        disabled={ isLoading || !isHasPermission }
        onClick={ onDelete }
      />
    </div>
  );
};
