import createRouter, { NavigationOptions, Router, State } from 'router5';
import { Params } from 'router5/dist/types/base';
import browserPlugin from 'router5-plugin-browser';
// import loggerPlugin from 'router5-plugin-logger';
import { authorizationGuard, permissionGuard } from './guard';
import {
  companyMiddleware,
  componentMiddleware,
  defaultRouteMiddleware,
  emailMiddleware,
  lastPageMiddleware,
  metadataMiddleware,
  modalMiddleware,
  onPageChangeMiddleware,
  pageTitleMiddleware,
  productMiddleware,
  redirectMiddleware,
  resolveMiddleware,
  setDefaultMiddleware,
  sidebarMiddleware,
  showNotificationMiddleware
} from './middleware';
import { loadingIndicatorPlugin, linkInterceptor } from './plugins';
import { Subject } from 'rxjs';

const routerOptions = (): any => ({
  allowNotFound: true,
  autoCleanUp: true
});

let routerInstance: Router;

const linkInterceptorCallback = (error: any) => {
  if (error) {
    console.error(error);
  }
};
const linkInterceptorOptions = (_routeName: string, _routeParams: object): object => {
  return {};
};

export const setRouter = (routesPromise: Promise<any[]>): Promise<Router> => {
  if (routerInstance) {
    throw Error('rootRouter init only once');
  }
  return routesPromise
    .then((routes) => {
      routerInstance = createRouter(routes, routerOptions());
      routerInstance.useMiddleware(
        modalMiddleware,
        sidebarMiddleware,
        setDefaultMiddleware,
        productMiddleware,
        authorizationGuard,
        emailMiddleware,
        companyMiddleware,
        redirectMiddleware,
        showNotificationMiddleware,
        lastPageMiddleware,
        defaultRouteMiddleware,
        permissionGuard,
        componentMiddleware,
        metadataMiddleware,
        resolveMiddleware,
        pageTitleMiddleware,
        onPageChangeMiddleware
      );
      routerInstance.usePlugin(
        // loggerPlugin,
        browserPlugin({
          useHash: false,
          forceDeactivate: false
        }),
        loadingIndicatorPlugin,
        linkInterceptor(linkInterceptorOptions, linkInterceptorCallback)
      );
      return routerInstance;
    });
};

export const router = (): Router => {
  if (!routerInstance) {
    throw Error('rootRouter was not init');
  }
  return routerInstance;
};

export const redirect = (name: string, routeParams: Params = {}, options: NavigationOptions = {}): void => {
  routerInstance.navigate(name, routeParams, options);
};

export const navigate = (href: string) => {
  const { pathname, search } = new URL(href);
  const data: null | State = routerInstance.matchPath(pathname + search);
  if (data) {
    routerInstance.clearCanDeactivate(routerInstance.getState().name);
    const { name, params } = data;
    routerInstance.navigate(name, params);
  }
};

export const routerStateReplaced$ = new Subject<State>();

export const replaceHistoryState = (
  name: string,
  params: Params = {}
): void => {
  routerInstance.replaceHistoryState(name, params);
  routerStateReplaced$.next(routerInstance.lastKnownState);
};

export const matchUrl = (url: string) => routerInstance.matchUrl(url);
