import { Api } from '@services/api/abstract';
import { Observable } from 'rxjs';
import { Collection, OpenAssetCount, OpenAssetInfo } from '@proliance-ai/typings';


const apiUrl = '/api/survey/asset/dashboard';

class AssetDashboardApiService extends Api {
  public getOpenAssets(parameters?: Record<string, any>): Observable<Collection<OpenAssetInfo>> {
    return this.get<Collection<OpenAssetInfo>>({
      url: `open-asset`,
      parameters
    });
  }

  public getOpenAssetCount(): Observable<OpenAssetCount> {
    return this.get<OpenAssetCount>({
      url: `open-asset/count`
    });
  }
}

export const assetDashboardApiService = new AssetDashboardApiService(apiUrl);
