import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Button, Loading } from '@proliance-ai/design-system';
import { OpenAssetInfo } from '@proliance-ai/typings';
import styles from './AssetHubTasks.styl';
import { redirect } from '@router';
import { AssetManagementTabsKey } from '@services';


interface Props {
  namespace: Namespace;
  assetHubTaskData: OpenAssetInfo[];
  loading: boolean;
}

const AssetHubTasks: FC<Props> = ({
  namespace,
  assetHubTaskData,
  loading
}) => {
  const { t } = useTranslation(namespace);

  if (loading) {
    return (
      <ul className={ styles.taskList }>
        <li className={ styles.loading }>
          <Loading/>
        </li>
      </ul>
    );
  }

  if (assetHubTaskData.length === 0) {
    return (
      <ul className={ styles.taskList }>
        <li className={ styles.empty }>
          { t('empty') }
        </li>
      </ul>
    );
  }

  const headline = t(`assetHub.headline`);
  const tabHead = <div className={ styles.heading }>{ headline }</div>;
  const assetClickHandler = (asset: OpenAssetInfo) => () => {
    const routeParams = { id: asset.id, sidebar: 'management', sidebarTab: 'administration' };
    switch (asset.assetType) {
      case AssetManagementTabsKey.DATA_STORAGE:
        return redirect('assetManagementTabAssetSidebarTab', { ...routeParams, routeTab: 'data-storage' });
      case AssetManagementTabsKey.THIRD_PARTY:
        return redirect('assetManagementTabAssetSidebarTab', { ...routeParams, routeTab: 'third-party' });
      case AssetManagementTabsKey.SOFTWARE:
        return redirect('assetManagementTabAssetSidebarTab', { ...routeParams, routeTab: 'software' });
      default:
        return redirect('assetManagement');
    }
  };

  const taskList = <ul className={ styles.taskList }>
    { assetHubTaskData.map((asset: OpenAssetInfo) => (
      <li key={ asset.id } className={ styles.taskItem }>
        <span className={ styles.taskName }>
          { asset.name }
        </span>

        <Button
          preset="primary"
          size="small"
          text={ t('assetHub.open') }
          onClick={ assetClickHandler(asset) }
        />
      </li>
    )) }
  </ul>;

  return (
    <>
      { tabHead }
      { taskList }
    </>
  );
};

export default AssetHubTasks;
