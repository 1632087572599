import React, { FC, ReactElement } from 'react';
import { Namespace } from 'i18next';
import { ITaskCount, Task, TaskCount, TaskCountDictionary } from '@services';
import { Tabs } from '@shared/ui';
import AssessmentTasksWidget from '../AssessmentTasks/AssessmentTasks';
import BasicTasksWidget from '../BasicTasks/BasicTasks';
import { OpenAssetInfo } from '@proliance-ai/typings';
import AssetHubTasks from '@pages/Dashboard/components/AssetHubTasks/AssetHubTasks';


interface Props {
  namespace: Namespace;
  tab: TaskCount;
  changeTabHandler: (value: TaskCount) => void;
  isBasicTabAvailable: boolean;
  basicTabHeading: ReactElement;
  basicTaskList: Task[];
  basicTaskLoading: boolean;
  isAssessmentTabAvailable: boolean;
  assessmentTabHeading: ReactElement;
  assessmentTaskData: TaskCountDictionary<ITaskCount>;
  assessmentTaskLoading: boolean;
  isAssetHubTabAvailable: boolean;
  assetHubTabHeading: ReactElement;
  assetHubTaskData: OpenAssetInfo[];
  assetHubTaskLoading: boolean;
}

const TaskTabs: FC<Props> = (
  {
    namespace,
    tab,
    changeTabHandler,
    isBasicTabAvailable,
    basicTabHeading,
    basicTaskList,
    basicTaskLoading,
    isAssessmentTabAvailable,
    assessmentTabHeading,
    assessmentTaskData,
    assessmentTaskLoading,
    isAssetHubTabAvailable,
    assetHubTabHeading,
    assetHubTaskData,
    assetHubTaskLoading
  }
) => {
  const basicTasksSection = isBasicTabAvailable && (
    <section
      key="BASIC"
      data-label={basicTabHeading}
      data-reload={true}
      data-key="BASIC"
    >
      <BasicTasksWidget
        namespace={namespace}
        basicTaskList={basicTaskList}
        loading={basicTaskLoading}
      />
    </section>
  );

  const assessmentTasksSection = isAssessmentTabAvailable && (
    <section
      key="ASSESSMENT"
      data-label={assessmentTabHeading}
      data-reload={true}
      data-key="ASSESSMENT"
    >
      <AssessmentTasksWidget
        namespace={namespace}
        assessmentTaskData={assessmentTaskData}
        loading={assessmentTaskLoading}
      />
    </section>
  );

  const assetHubTasksSection = isAssetHubTabAvailable && (
    <section
      key="ASSET_HUB"
      data-label={assetHubTabHeading}
      data-reload={true}
      data-key="ASSET_HUB"
    >
      <AssetHubTasks
        namespace={namespace}
        assetHubTaskData={assetHubTaskData}
        loading={assetHubTaskLoading}
      />
    </section>
  );

  const content = [
    basicTasksSection,
    assessmentTasksSection,
    assetHubTasksSection
  ].filter(Boolean);

  return (
    <Tabs
      dataAttributesDictionary={{
        test: { task: 'widget' },
        guide: { task: 'widget' }
      }}
      activeTab={tab}
      onChange={changeTabHandler}
    >
      {content}
    </Tabs>
  );
};

export default TaskTabs;
