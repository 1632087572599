import { Observable } from 'rxjs';
import { ComplianceScoreData, FunctionalAreaScoreData } from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey/stats';

class DashboardApiService extends Api {
  public getComplianceScore(): Observable<ComplianceScoreData> {
    return this.get({ url: 'compliance-score/general' });
  }

  public getDetailedComplianceScore(): Observable<FunctionalAreaScoreData> {
    return this.get({ url: 'compliance-score/detailed' });
  }
}

export const dashboardApiService = new DashboardApiService(apiUrl);
